// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$primary: #049DD7;
$second: #FDB43D;
$blue: #10456C;
$light-blue: #D7E8FA;
$light-primary: #0154AD;
$gradient1: #C956AA;
$gradient2: #536bd1;
$gradient3: #538FD1;
$red-gradient1: #C94242;
$red-gradient2: #EE6161;
$black: #1C0D3F;
$red: #F27070;
$green: green;
$grey: #606060;
$grey-tarif: rgba(#1c0d3f, 0.05);
$grey-cod: #c0d1DB;
$grey-light: rgba(white, 0.5);
$grey-lighter: rgba($grey, 0.3);
$grey-footer: #2F343B;
$white-footer: #ECF0F5;
$white-footer2 : darken($white-footer, 0.5);
$black-overlay-light: rgba(0, 0, 0, 0.7);
$blue-overlay-light: rgba($second, 0.9);


// Specific
// =============================================================================
// Link
$link-color:       $grey;
$link-focus-color: $second;
$link-hover-color: $blue;
// Selection
$selection-text-color:       $primary;
$selection-background-color: #FFFFFF;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
