.c-roundBtn_container{
    .c-roundBtn{
        height: 5rem;
        min-width: 5rem;
        background: $primary;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        
        svg{
            padding-left: 3px;
        }

        .c-roundBtn_span{
            z-index: 1;
            display: flex;
        }

        &.-orga{
            height: 2.5rem;
            min-width: 2.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            cursor: pointer;
        }
    }

    &.-no-anim{
        .c-roundBtn{
            background: white;
        }
    }

    &.-cursor{
        cursor: pointer;
    }
}

