.b-icones{
    svg{
        &:first-of-type{
            position: relative;
            bottom: 1rem;
            transform: rotate(180deg);
        }

        &:last-of-type{
            position: relative;
            top: 1rem;
        }
    }

    @media (max-width: $from-tiny){
        .b-icones_svg{
            display: none;
        }
        
        .b-icones_texte{
            width: 100% !important;
        }
    }
}
