.b-paragraphe{
    position: relative;
    z-index: 1;

    .b-paragraphe_title{
        font-style: italic;
    }

    .b-paragraphe_btn{
        &.-left{
            margin: 2rem 0 0 0;
        }
    }
    
    .c-heading{
        &.-h2{
            font-style: italic;
            font-weight: 400;
            -webkit-text-stroke: 1px currentColor;
        }

        &.-h3{
            font-weight: 400;
            -webkit-text-stroke: 1px currentColor;
        }
    }

    @media (max-width: $from-small) {
        .b-paragraphe_text_container{
            margin-left: unset !important;
            padding-top: 1rem;

            .c-text{
                width: 100% !important;
            }

            .b-paragraphe_text_container_div{
                flex-direction: column;
            }
        }
    }

    @media (max-width: $from-tiny){
        .b-paragraphe_text_container{
            flex-direction: column;
        }
    }
}
