// ==========================================================================
// Objects / Container
// ==========================================================================

//
// Page-level constraining and wrapping elements.
//
// > In programming languages the word *container* is generally used for structures
// that can contain more than one element.
// > A *wrapper* instead is something that wraps around a single object to provide
// more functionalities and interfaces to it.
// @link http://stackoverflow.com/a/13202141/140357
//

.o-container {
    margin-right: auto;
    margin-left: auto;
    padding-right: rem($padding);
    padding-left: rem($padding);
    max-width: rem($container-width + ($padding * 2));
    &.-huge {
      max-width: rem($container-large-width + ($unit-huge));
    }
    &.-large {
      max-width: rem($container-large-width + ($padding * 2));
    }
    &.-medium {
      max-width: rem($container-medium-width + ($padding * 2));
    }
    &.-small {
      max-width: rem($container-small-width + ($padding * 2));
    }
    @media (max-width: $to-small) {
      padding-left: rem($unit-small);
      padding-right: rem($unit-small);
    }
}
