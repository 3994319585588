.b-timeline{
    .b-timeline_blockTitle{
        display: flex;
        align-items: center;

        p{
            white-space: nowrap;
            padding-right: 2rem;
        }

        &::after{
            content: '';
            width: 100%;
            height: 1px;
            background-color: $blue;
        }
    }
    .b-timeline_item{
        margin-bottom: 2rem;
        display: flex;
        align-items: flex-start;

        .b-timeline_head{
            display: flex;
            align-items: center;

            &::after{
                content: '';
                width: rem(120px);
                height: 1px;
                margin: 0 2rem;
                background-color: $blue;
            }
        }

        .b-timeline_title{
            padding: 1rem 1.5rem;
            border-bottom-left-radius: 1rem;
        }
        
        .b-timeline_content{
            figure{
                height: 350px;
            }
        }

        @media(max-width: $from-small){
            flex-direction: column;
            align-items: center;

            .b-timeline_head{
                flex-direction: column;
                align-items: center;

                &::after{
                    width: 1px;
                    height: 2rem;
                    margin: 1rem 0;
                }
            }
        }

        // &.t-clicked{
        //     .b-timeline_title{
        //         color: $red;

        //         &::after, &::before{
        //             transform: scaleX(100%);
        //         }
        //     }

        //     .b-timeline_content{
        //         transform: translateY(0rem);
        //         visibility: visible;
        //         height: 100%;
        //         opacity: 1;
        //     }
        // }
    }
}
