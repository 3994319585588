.b-accueil_portail {
    min-height: 100svh;
    .b-accueil_portail_container {
        min-height: 100svh;
    }
    .b-accueil_portail_img {
        max-height: rem(120px);
    }

    .b-accueil_portail_deco {
        position: absolute;
        right: rem(-$unit);
        top: 0;
        bottom: 0;
        transform: scaleX(-1);
        svg {
            height: 100%;
        }
    }

    .b-accueil_portail_wrap {
        position: relative;
        z-index: 2;
    }
    
    @media(max-width: $to-small) {
        .b-accueil_portail_card {
            text-align: center;
            align-items: center;
        }
    }
}