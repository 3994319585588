.c-flap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: rem($unit-small) rem($unit-small) 3rem 0;
  pointer-events: none;
  .c-flap_bg {
    pointer-events: auto;
    &::before {
      visibility: hidden;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
      transition: $speed $easing;
    }
  }
  .c-flap_nav {
    visibility: hidden;
    opacity: hidden;
    display: flex;
    flex-direction: column;
    transform: scaleY(0);
    transition: $speed $easing;
    transform-origin: left bottom;
    pointer-events: auto;
    position: relative;
    left: 0.4rem;
    bottom: 4rem;

    .c-flap_link {
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end !important;
      transition: none;
      font-weight: $semibold;
      color: white;
      font-size: $font-size-h4;
      font-weight: 400;

      span{
        svg{
          width: 1rem;
        }
      }

      img{
        width: 1.5rem;

        path{
          fill: $primary;
        }
      }

      .b-intro_linkComponent{
        width: rem(68px);
        height: rem(68px);
        order: 2;
        border-radius: 50%;
      }
    }
  }
  .c-flap_button {
    background-color: white;
    border-radius: 50%;
    border: 1px solid rgba($color: #274A69, $alpha: 0.25);
    color: $white;
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    z-index: 2;
    pointer-events: auto;
    text-align: center;
    transition: width $speed $easing;
    width: rem(68px);
    height: rem(68px);
    display: block;
    margin-top: 2rem;
    svg {
      width: 1.5rem;
    }
  }
  .c-flap_button {
    .c-flap_icon-close {
      display: none;
    }

    .c-flap_button_icon{
      z-index: 2;
      position: relative;
    }

    &::after{
      content: '';
      background-color: $primary;
      width: 100%;
      height: 100%;
      transform-origin: center;
      transform: scale(0%);
      transition: 0.3s ease-out;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
    }

    &:hover{
      &::after{
        transform: scale(100%);
      }

      svg{
        path, line, rect{
          fill: white;
        }
      }
    }
  }
}

.has-flapOpen {
  .c-flap {
    .c-flap_bg {
      &::before {
        visibility: visible;
      }
    }
    .c-flap_nav {
      visibility: visible;
      opacity: 1;
      transform: scaleY(1);
    }
    .c-flap_button {
      background-color: $primary;
      .c-flap_icon-open {
        display: none;
      }
      .c-flap_icon-close {
        display: block;
        z-index: 2;
        position: relative;
      }

      svg{
        path, line, rect{
          fill: white;
        }
      }
      /*&.-hide {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
      }*/

      &::after{
        position: inherit;
      }
    }
  }
}
