.c-nav {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9;
  transition: 0.6s $easing;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $primary;
  height: 0vh;
  overflow: hidden;
  overflow-x: hidden!important;

  .c-nav_primary_container{
    margin-top: calc(#{$header-height} / 2);
    position: relative;
    overflow: auto;
    height: 100%;
    padding: 3rem 0;

    svg{
      position: absolute;
      top: -150px;
      left: calc(50% - 222px);
      width: 455px;
    }

    .c-nav_cross{
      position: absolute;
      top: 3rem;
      right: 5rem;
      width: 53px;
      height: 53px;
      z-index: 5;
      cursor: pointer;

      &::after{
        content: '';
        position: absolute;
        height: 4px;
        width: 70px;
        left: 0;
        bottom: 0;
        transform-origin: left;
        transform: rotate(-45deg);
        background-color: $blue;
        transition: 0.3s $easing;
      }

      &::before{
        content: '';
        position: absolute;
        height: 4px;
        width: 70px;
        top: 0;
        left: 0;
        transform-origin: left;
        transform: rotate(45deg);
        background-color: $blue;
        transition: 0.3s $easing;
      }

      &:hover{
        &::after, &::before{
          background-color: white;
        }
      }
    }
    
    .c-nav_primary{
      .c-nav_primary_component {
        transition: padding-left 0.3s $easing, transform 0.9s $easing;
        transform: translateY(10rem);
      }
      .c-nav_primary_link{
        transition: padding-left 0.3s $easing, transform 0.9s $easing;
        display: flex;
        align-items: center;
        text-align: left;

        .c-nav_primary_plus{
          position: relative;
          width: 15px;
          height: 15px;
          flex-shrink: 0;
          pointer-events: none;
          
          &::before{
            content: '';
            width: 15px;
            height: 2px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: currentColor;
            transition: $speed $easing;
          }

          &::after{
            content: '';
            height: 15px;
            width: 2px;
            position: absolute;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            background-color: currentColor;
            transition: $speed $easing;
          }
        }

        &.-underlink{
          cursor: pointer;
        }
        &:hover{
          color: $blue;
          &:not(.-underlink){
            padding-left: 1rem;
          }
        }
      }

      .c-nav_primary_component{
        &.h-clicked {
          .c-nav_primary_link {
            color: $blue;
            .c-nav_primary_plus {
              &::after {
                transform: scale(0);
              }
            }
          }
          .c-nav_primary_sublink{
            visibility: visible;
            height: fit-content;
            padding-bottom: $unit-small;
          }
        }
      }
      .c-nav_primary_sublink{
        height: 0;
        visibility: hidden;
        transition: height $speed $easing 0.1s;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: $unit-tiny;
        color: $white;
      }

      @media (max-width: $from-midsmall){
        flex-direction: column;
      }
    }
    .c-nav_cross, .c-nav_primary_left .-h1, svg{
      transform: translateY(-10rem);
      transition: 0.9s $easing;
    }

    .c-nav_primary_left a, .c-nav_primary_center, .c-nav_primary_right{
      transform: translateY(10rem);
      transition: transform 0.9s $easing;
    }
  }

  .c-nav_primary_left{
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 3;
    width: 100%;
  }

  .c-nav_primary_center{
    align-self: flex-end;
  }

  .c-nav_primary_right{
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px 0 0px 10px;
    position: absolute;
    overflow: hidden;
    right: 0;

    figure{
      overflow: hidden;
      img{
        transition: 0.3s $easing;
      }
    }

    &:hover{
      figure{
        img{
          transform: scale(1.05);
        }
      }
    }
  }
  
  .c-nav_container{
    visibility: hidden;
    height: 100%;
  }

  .c-nav_content{
    width: 100%;
  }

  .c-nav_roundBtn{
    height: 170px;
    min-width: 170px;
    margin: 0 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>a{
      height: 170px;
      min-width: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light-blue;
      border-radius: 50%;
      transition: 0.3s ease-out, transform 0.6s ease-out, visibility 0s;
      cursor: pointer;
      // transform: rotate(-90deg);
      visibility: hidden;
    }

    &:hover{
      &>a{
        height: 140px;
        min-width: 140px;
      }
    }

  }

  @media (max-width: $from-large) {
    overflow: scroll !important;

    .c-nav_primary_container{
      overflow: inherit;
    }

    .c-nav_primary{
      flex-direction: column;
      gap: 3rem;

      .c-nav_primary_center{
        align-self: inherit;
      }
    }
  }

  @media (max-width: $from-midsmall){
    .c-nav_primary{
      .c-nav_roundBtn{
        order: 1;
      }

      .c-nav_primary_right{
        position: relative;
        align-self: flex-start;
        border-radius: unset;
        margin-top: 5rem;
        height: fit-content !important;
        
        figure{
          display: none;
        }
      }
      .c-nav_adresse{
        order: 2;
        margin-top: 3rem;
      }

      .c-nav_contact{
        order: 3;
        margin-top: 1rem;
      }
    }
  }

  @media (max-width: $from-small){
    .c-nav_container{
      .c-nav_title{
        font-size: 3.5rem;
      }
      
    }
  }

  @media (max-width: $from-tiny){
    .c-nav_primary{
      .c-nav_primary_link{
        font-size: 2.3rem !important;
      }
    }
  }
}

.c-nav_link {
  &:hover {
    color: $primary;
  }
}

.has-navOpen {
  .c-header{
    .c-header_nav_icon_span{
      &:first-of-type{
        transform: translateY(calc(0.4rem - 1px)) rotate(45deg);
      }

      &:nth-of-type(2){
        display: none;
      }

      &:last-of-type{
        transform: translateY(calc(-0.4rem + 1px)) rotate(-45deg);
      }
    }

    .c-header_nav_burger{
      &:hover{
        .c-header_nav_icon_burger{
          height: 0.8rem !important;
        }
      }
    }
  }
  .c-nav {
    height: 100vh;
    overflow: visible;
    .c-nav_container{
      visibility: visible;
      .c-nav_roundBtn{
        &>a{
          // transform: rotate(0deg);
          visibility: visible;
        }
      }
    }
  }

    .c-nav_cross,
    .c-nav_primary_left .-h1,
    svg{
      transform: translateY(0) !important;
    }

    .c-nav_primary_left a,
    .c-nav_primary_left .c-nav_primary_component,
    .c-nav_primary_center,
    .c-nav_primary_right {
      transform: translateY(0) !important;
    }
}
