.b-intro{
    display: flex;
    flex-direction: column;

    &.-accueil{
        position: relative;
    }

    .b-intro_top{
        background: linear-gradient(to right top, $gradient1, $gradient2, $gradient3);
        display: flex;
        align-items: center;
        height: calc(100vh - 144px);

        .b-intro_img{
            height: 100%;
        }
    }

    .b-intro_container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 8rem;

        .b-intro_content_container{
            color: white;
            display: flex;
            align-items: center;
            width: rem(700px);
            overflow: hidden;
            padding-left: 4rem;
            padding-top: 3rem;

            .b-intro_content_component{
                min-width: rem(700px);
                transition: 0.3s ease-out;

                p{
                    position: relative;

                    span{
                        font-size: 135px;
                        color: rgba($color: #fff, $alpha: 0.12);
                        position: absolute;
                        left: -3rem;
                        bottom: -1rem;
                    }

                    &:last-of-type{
                        padding-right: 12rem;
                    }
                }
            }
        }

        .b-intro_nav{
            display: flex;
            align-items: center;
            margin-top: 8rem;
            margin-left: 2rem;

            .b-intro_nav_component{
                width: rem(100px);
                height: 5px;
                background-color: rgba($color: #fff, $alpha: 0.34);
                margin-left: 2rem;
                cursor: pointer;
                transition: 0.3s ease-out;

                &:first-of-type{
                    border-bottom-left-radius: 5px;
                    margin-left: inherit;
                }

                &:hover{
                    background-color: white;
                }

                &.i-clicked{
                    background-color: white;
                }
            }
        }
    }

    .b-intro_bottom{
        background-color: rgba($color: $blue, $alpha: 0.15);
        height: rem(200px);
        display: flex;
        align-items: center;
        justify-content: center;

        .b-intro_bottom_container{
            display: flex;
            align-items: center;
            justify-content: center;

            .b-intro_bottom_component{
                height: rem(75px);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1rem;
                background-color: white;
                width: rem(268px);
                border-bottom-left-radius: 1rem;
                
                &:not(:last-of-type){
                    margin-right: 3rem;
                }

                .b-intro_bottom_icone{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                span{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding-left: 1rem;
                    border-left: 1px solid $grey-light;
                }
            }
            
            
        }

        @media (max-width: 1300px){
            height: rem(220px);

            .b-intro_bottom_container{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 1rem;

                .b-intro_bottom_component{
                    margin-right: inherit !important;
                }
            }
        }

        @media (max-width: 1300px){
            .b-intro_bottom_container{
                grid-template-columns: 1fr 1fr;
            }
        }

        @media (max-width: 510px){
            height: rem(420px);

            .b-intro_bottom_container{
                grid-template-columns: 1fr;
            }
        }
    }

    @media (max-width: 1350px){
        .b-intro_container{
            .b-intro_content_container{
                .b-intro_content_component{
                    p{
                        &:last-of-type{
                            padding-right: 14rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1300px){
        .b-intro_container{
            .b-intro_content_container{
                .b-intro_content_component{
                    p{
                        &:last-of-type{
                            padding-right: 20rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 1100px){
        .b-intro_img{
            display: none;
        }

        .b-intro_container{
            margin-top: inherit;

            .b-intro_content_container{
                width: inherit;
                padding-top: 5rem;

                .b-intro_content_component{
                    min-width: 100vw;

                    p{
                        &:last-of-type{
                            padding-right: 10rem;
                        }

                        span{
                            font-size: 100px;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 485px){
        .b-intro_container{
            .b-intro_nav{
                .b-intro_nav_component{
                    margin-left: 1rem;
                    width: rem(65px);
                }
            }

            .b-intro_content_container{
                .b-intro_content_component{
                    p{
                        span{
                            display: none;
                        }

                        &:last-of-type{
                            padding-right: 5rem;
                        }
                    }
                }
            }
        }
    }
}
