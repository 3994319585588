.b-annuaire{
    .b-annuaire_deco{
        display: flex;
        align-items: center;
        width: 100%;

        &::before{
            content: '';
            width: 100%;
            height: 2px;
            margin-right: 2rem;
            background-color: $grey;
        }

        &::after{
            content: '';
            width: 100%;
            height: 2px;
            margin-left: 2rem;
            background-color: $grey;
        }
    }
    .b-annuaire_container{
        border-bottom-left-radius: 1.5rem;
        min-width: fit-content;

        .b-annuaire_input{
            border-bottom-left-radius: 0.5rem;

            &:last-of-type{
                min-width: rem(300px);
            }
        }

        .c-button{
            padding: calc(0.5rem - 3px) 1rem;
        }
    }
}
