.b-etapes {
    .b-etapes_item_head{
        span{
            position: relative;
            padding: 1.5rem 0.75rem 0.75rem 3rem;
            border-radius: rem(8px);
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            border: 1px solid $black;
        }
        
        @media(max-width: 500px){
            flex-direction: column;
            align-items: flex-start !important;

            span{
                margin-bottom: 2rem;
            }
        }
    }
}
