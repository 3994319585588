.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .c-header_bottom{
    width: 100%;
    .logo{
      width: rem(196px);
    }

    .c-header_menu_component{
      min-width: rem(50px);
      height: rem(50px);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .c-header_nav_burger{
      min-width: rem(44px);
      height: rem(44px);
      border-radius: 50%;
      border: 3px solid $second;
      transition: 0.3s ease-out;
      background-color: $second;
      position: relative;

      .c-header_nav_icon_burger{
        height: rem(12px);
        transition: 0.3s ease-out;
        display: flex;
        flex-direction: column;

        &.-search{
          height: 1rem;
          z-index: 1;
        }
      }

      .c-header_nav_icon_span{
        display: flex;
        width: rem(22px);
        height: 2px;
        min-height: 2px;
        background-color: $black;
        transition: 0.3s ease-out;
        z-index: 1;

        &:first-of-type{
          margin-bottom: 4px;
        }

        &:last-of-type{
          margin-top: 4px;
        }
      }

      &::after{
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        transition: transform 0.3s ease-out;
      }

      &:hover{
        &::after{
          transform: scale(0);
        }
      }
    }

    &.-scrolling{
      nav{
        p{
          display: none;
        }
      }

      .c-header_logo{
        .logo{
          &.-black{
            transform: translateX(-50vw);
          }
        }

        .logo{
          &:last-of-type{
            transform: translateY(5.5rem);
            transition: 0.3s ease-out;
          }
        }
      }
    }
  }

  @media (max-width: $from-small){
    .c-header_bottom{
      .c-header_nav{
        .c-header_buttons{
          .c-header_menu{
            .c-header_nav_menu{
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: $from-tiny) {
    .c-header_bottom {
      .-logo{
        width: 12rem;
      }
      .c-header_nav {
        .c-header_socials {
          display: none;
        }
      }
    }
  }
}

.c-header_socials_component {
  height: rem(44px);
  width: rem(44px);
  border-radius: 50%;
  background-color: $black;
  position: relative;
  color: $black;

  svg {
    height: rem(16px);
    z-index: 1;

    path {
      transition: $speed $easing;
    }
  }

  &::after {
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background-color: $second;
    border-radius: 50%;
    position: absolute;
    left: -1px;
    top: -1px;
    transition: transform $speed $easing;
    z-index: 0;
  }

  &:hover {
    background-color: $black;

    svg {
      path {
        fill: white;
      }
    }

    &::after {
      transform: scale(0);
    }
  }
}

