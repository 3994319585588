.b-tiroirs {
    .b-tiroirs_content{
        display: flex;
        flex-direction: column;

        span{
            p{
                margin: 0;
            }
        }
    }

    @media (max-width: $from-medium){
        .c-accordion{
            .c-accordion_main{
                .c-accordion_main_wrap{
                    .c-accordion-border{
                        flex-direction: column;

                        .b-tiroirs_content, figure{
                            width: 100% !important;
                        }

                        .b-tiroirs_content{
                            order: 2;
                        }

                        figure{
                            order: 1;
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 580px){
        .b-tiroirs_btns{
            display: flex;
            flex-direction: column;

            a{
                &:first-of-type{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
