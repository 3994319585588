.o-scroll {
	min-height: 100vh;
	overflow: hidden;
}

[data-scroll-container],
[data-scroll-section] {
	transform-style: preserve-3d;
	will-change: transform;
}

html.has-scroll-smooth {
	overflow: hidden;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
