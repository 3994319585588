.c-footer{
    .c-footer_logo {
        max-height: rem(120px);
    }
    .c-footer_top{
        // height: rem(335px);
        align-self: center;
        padding-top: 8rem;

        .c-footer_socials{
            width: rem(44px);
            height: rem(44px);
            border-radius: 50%;
            background-color: $white;
            position: relative;
            color: $black;

            svg{
                z-index: 1;
            }

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: $second;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 0;
                transition: transform $speed $easing;
                z-index: 0;
            }
        
            &:hover {
                background-color: white;
                &::after {
                    transform: scale(0);
                    background-color: $second;
                }
            }
        }

        @media(max-width: $to-small) {
            padding-top: rem($unit);
        }
    }


    .c-footer_bottom{
        // height: rem(65px);
        border-top: 1px solid white;
        padding: 0.5rem;
    }

    svg{
        height: 1rem;
    }

    .c-footer_link {
        &:hover {
            color: $primary;
        }
    }

    @media (max-width: $from-small){
        .c-footer_bottom {
            flex-direction: column;
            justify-content: center !important;

            div{
                text-align: center;
                padding-top: 0.5rem;
            }

            .c-footer_top_menu{
                a{
                    margin-left: unset;
                }
            }
        }

        .c-footer_top{
            // flex-direction: column;
            // justify-content: center !important;
            // align-items: center !important;
            // padding-top: unset !important;
            // gap: 3rem;
            // height: 42rem;
        }
    }
}
