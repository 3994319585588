// ==========================================================================
// Base / Fonts
// ==========================================================================

// Regular

@include font-face(
    $font-Mandala,
    "fonts/Mandala/Mandala_Regular",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 400;
}

@include font-face(
    $font-Roboto,
    "fonts/Roboto/Roboto-Regular",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 400;
}

//Bold

@include font-face(
    $font-Mandala,
    "fonts/Mandala/Mandala_Bold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 700;
}

@include font-face(
    $font-Roboto,
    "fonts/Roboto/Roboto-Bold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 700;
}

// Medium

@include font-face(
    $font-Mandala,
    "fonts/Mandala/Mandala_Regular",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 500;
}

@include font-face(
    $font-Roboto,
    "fonts/Roboto/Roboto-Medium",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style : normal;
    font-weight: 500;
}
