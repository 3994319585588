.b-tableau{
    .b-tableau_container{
        padding: 1.5rem;
        border-radius: 8px;
        border: 2px solid $black;

        .b-tableau_header{
            padding-bottom: 1.5rem;
            margin-bottom: 1.5rem;
            border-bottom: 2px solid $black;
        }

        .b-tableau_body{
            .b-tableau_column{
                padding-bottom: 1.5rem;
                margin-bottom: 1rem;
                border-bottom: 1px solid $black;
            }
        }
    }
}
