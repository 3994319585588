.b-accueil {
    position: relative;
    z-index: 3;

    .b-accueil_container{
        border-radius: 1rem;
        height: rem(470px);
        overflow: hidden;

        .b-accueil_left{
            height: 100%;

            h1{
                font-style: italic;
            }
        }

        .b-accueil_right{
            height: 100%;

            .b-accueil_slider{
                height: 100%;
                width: 100%;
                overflow: hidden;
                position: relative;

                svg{
                    position: absolute;
                    left: -2px;
                    top: 0;
                    z-index: 2;
                    height: 100%;
                }

                figure{
                    min-width: 100%;
                    height: 100%;
                    transition: 0.6s ease-out;
                }

                .b-accueil_dots{
                    display: flex;
                    align-items: center;
                    position: absolute;
                    left: 0;
                    bottom: 3rem;
                    z-index: 2;
                    gap: 6px;

                    .round{
                        width: rem(16px);
                        height: rem(16px);
                        border-radius: 50%;
                        background-color: $primary;
                        position: relative;
                        cursor: pointer;
                        border: 2px solid $black;
                        transition: 0.3s ease-out;

                        &:hover, &.round-active{
                            background-color: $black;
                        }
                    }
                }
            }
        }
    }

    .b-accueil_bg_deco{
        position: absolute;
        right: -6rem;
        z-index: -1;
        top: 0;

        &.-actu-eve{
            left: -6rem;
            top: -20rem;
            width: fit-content;
        }

        &.-actu-eve2{
            bottom: -12rem;
            right: -8rem;
            top: unset;
        }
    }

    @media (max-width: $from-tiny){
        .b-accueil_container{
            flex-direction: column;

            .b-accueil_left{
                width: 100% !important;

                div{
                    padding-top: 1.5rem;
                }
            }

            .b-accueil_right {
                height: 40%;
                width: 100% !important;

                svg{
                    display: none;
                }
            }
        }
    }

}
