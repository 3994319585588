.b-infos{
    .b-infos_top{
        gap: 1rem;
        min-height: rem(170px);

        .b-infos_liste{
            // height: 100%;
            border-radius: 0.5rem;

            ul{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
                padding: 1.5rem;
                min-height: rem(170px);
                
                li{
                    display: flex;
                    align-items: center;
                    font-size: rem(16px);
                    color: $black;
                    font-weight: 500;

                    &::before{
                        content: '';
                        width: rem(18px);
                        min-width: rem(18px);
                        height: rem(18px);
                        border-radius: 50%;
                        background-color: white;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }

    .b-infos_bottom{
        min-height: rem(170px);
        margin-top: 1rem;
        gap: 1rem;

        .b-infos_temoignage{
            height: 100%;
            min-height: rem(170px);
            border-radius: 0.5rem;
            padding: 1.5rem;

            .-border{
                padding-bottom: 1rem;
                margin-bottom: 1rem;
                border-bottom: 1px solid $black;
                display: flex;
                align-items: center;
                gap: 0.5rem;

                div{
                    display: flex;
                    align-items: center;

                    &::after{
                        content: '';
                        height: 3px;
                        width: 3px;
                        border-radius: 50%;
                        background-color: $black;
                        margin-left: 0.5rem;
                    }
                }
            }
        }
    }

    .b-infos_chiffres{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem;
        border: 1px solid $black;
        border-radius: 0.5rem;
        // height: 100%;
        min-height: rem(170px);
    }

    @media (max-width: $from-medium){
        .b-infos_top, .b-infos_bottom{
            flex-wrap: wrap;

            .b-infos_chiffres{
                width: calc(50% - 0.5rem) !important;
            }

            .b-infos_liste, .b-infos_temoignage{
                width: calc(100% - 0.5rem) !important;
            }
        }
    }

    @media (max-width: $from-tiny){
        .b-infos_top, .b-infos_bottom{
            flex-direction: column;

            .b-infos_chiffres{
                width: 100% !important;
            }
        }
    }
}
