.c-button {
  padding: rem(12px) 1rem rem(12px) rem(24px);
  border-radius: 2rem;
  position: relative;
  width: max-content;
  background-color: $second;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto';
  transition: 0.3s cubic-bezier(.46, .46, .3, .99);

  .c-button_deco{
    background-color: white;
    width: calc(1rem + 4px);
    height: calc(1rem + 4px);
    border-radius: 50%;
    position: relative;

    &::after{
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: $second;
      transition: transform 0.3s cubic-bezier(.46, .46, .3, .99);
    }
  }

  &.-black{
    background-color: $black;
    color: white;

    .c-button_deco{
        background-color: $second;

        &::after{
            background-color: $black;
        }
    }
  }

  &.-white {
    background-color: $white;
    color: $second;
  }

  &.-round {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(44px);
    width: rem(44px);
    border-radius: 50%;
  }

  &:hover{
    color: white;
    background-color: $black;
    
    .c-button_deco{
        background-color: $second;
        &::after{
            transform: scale(0);
            background-color: $black;
        }
    }
    &.-black{
        background-color: $second;
        color: $black;

        .c-button_deco{
            background-color: white;

            &::after{
                background-color: $second;
                transform: scale(0);
            }
        }
    }

    &.-bg-black {
        background-color: $white;
        color: $black;

        .c-button_deco{
            background-color: $second;
        }
    }
  }
}

.c-footer{
    .c-button{
        &:hover{
            background-color: white;
            color: $black;

            .c-button_deco{

                &::after{
                    background-color: white;
                }
            }
        }
    }
}

$width  : fit-content;
$height : fit-content;
$primary_color : #3084AC;
$border_color--hover : lighten($primary_color, 80);
$stroke : 2px;
$border: $stroke solid white;
$transition_duration:150ms;
$pipeline_width: 100%;
$pipeline_height: 100%;
$pipes : 4;

@function border-pipeline ($transition_duration, $pipe) {
    @return $transition_duration*$pipe;
}

@mixin border-animation($dimension, $easing ,$pipe ) 
{
    transition: $transition_duration  $dimension $easing border-pipeline($transition_duration,$pipe);
    -web-kit-transition: $transition_duration  $dimension $easing border-pipeline($transition_duration,$pipe);
}

.box {
    width: $width ;
    height: $height ;
    border:$border;
    text-align: center;
    position:relative;
    cursor: pointer;
    transition: ($transition_duration*$pipes)+$transition_duration ease;
    border-bottom-left-radius: 1rem;
    &:before {
        position: absolute; 
        width: 0; 
        height:$stroke; 
        content: ''; 
        background: $primary;
        top:-$stroke; 
        left:0; 
        @include border-animation( width, ease, 3);
    }
    &:after {
        position: absolute; 
        width:$stroke; 
        height:0;
        content: ''; 
        background: $primary; 
        top:-$stroke; 
        right: -$stroke; 
        @include border-animation( height, ease, 2);
    }
    &-inner{
      position: relative;
    }
    &-inner:after{
        position: absolute; 
        width: 0; 
        height:$stroke; 
        content: ''; 
        background: $primary; 
        bottom:0; 
        right: 0;
        @include border-animation( width, ease, 1);
    }
    &-inner:before {
        position: absolute; 
        width: $stroke; 
        height:0;content: ''; 
        background: $primary; 
        bottom:0; 
        left: 0; 
        @include border-animation( height, ease, 0); 
    }
    &:hover{
        border-color:transparent;
        &:before{
            width: $pipeline_width; 
            @include border-animation( width, ease, 0); 
        }
        &:after {
            height:$pipeline_height; 
            @include border-animation( height, ease, 1); 
        }
        .box-inner:after {
            width: $pipeline_width; 
            @include border-animation( width, ease, 2);
        }
        .box-inner:before {
            height:$pipeline_height; 
            @include border-animation( height, ease, 3);
        }
    }
}
