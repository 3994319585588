.c-card {
  position: relative;
  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
    .c-card_image {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      .c-figure {
        width: 100%;
        height: 100%;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      img {
        transition: $speed $easing;
      }
    }
    .c-card_wrap {
     
    }
    &:hover {
      .c-card_image {
        overflow: hidden;
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &.-actualite {
    border-radius: 1rem;
    overflow: hidden;

    .c-card_wrapper{
      // height: 310px;
    }

    .c-card_image{
      position: relative;
      // height: 100%;

      svg{
        position: absolute;
        top: 0;
        left: -1px;
        height: 100%;
        z-index: 1;
      }
    }
    figure{
      position: relative;
      overflow: hidden;
      border-bottom-left-radius: 2rem;

      img{
        transition: 0.3s ease-out;
      }
    }

    .c-card_content{
      // height: 100%;
    }

    .c-card_body{
      display: flex;
      flex-direction: column;
      height: 100%;
      gap: rem($unit-small);

      p{
        margin-bottom: unset;
      }

      div{
        transition: 0.3s ease-out;
      }

      .c-card_body_text{
        display: flex;
        align-items: center;

        span{
          padding: 0 1rem;
        }
      }
    }

    .ensavoir {
      display: flex;
      margin: auto 0 1.2rem 0;

      .dot {
        &::after {
          background-color: $primary;
        }
      }
    }

    &:hover{
      figure{
        img{
          transform: scale(1.05);
        }
      }
    }

    .c-card_content{
      display: grid;
      align-items: flex-start;
      padding: 1rem;
      min-height: 230px;
    }

    @media(max-width: $from-tiny){
      .c-card_wrapper{
        flex-direction: column;
        height: unset;

        .c-card_content{
          width: 100% !important;
        }

        .c-card_image{
          width: 100% !important;
          height: rem(200px);

          figure{
            border-bottom-left-radius: 1rem;
          }
          
          svg{
            display: none;
          }
        }
      }
    }
  }

  &.-evenements{
    overflow: hidden;
    border-top: 2px solid $black;

    .c-card_card{
      position: relative;
      height: 100%;
      z-index: 1;

      .c-card_left{
        border-radius: 1rem;
        flex-shrink: 0;
      }

      .c-card_right{
        flex: 1;
      }
    }

    .c-card_container{
      display: flex;
      justify-content: space-between;
      height: 100%;
    }
    
    .c-card_front{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      width: 50%;
      order: 2;

      .c-card_front_title{
        transition: 0.8s cubic-bezier(.46,.46,.3,.99);
        transition-delay: 0.07s;
        transform: translateX(calc(-125%));
      }

      .c-card_front_text{
        transition: 0.8s cubic-bezier(.46,.46,.3,.99);
        transform: translateX(calc(-100%));
      }

      .c-card_fleche{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        transform: translateX(calc(-100%));

        .c-card_fleche_content{
          height: rem(48px);
          min-width: rem(48px);
        }

        &::before{
          content: '';
          width: 100%;
          height: 1px;
          background-color: white;
          margin-right: 2rem;
        }

        span{
          height: rem(48px);
          min-width: rem(48px);
          background-color: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.8s cubic-bezier(.46,.46,.3,.99);
        }
      }
    }

    .c-card_back{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      order: 1;
      transition: 0.8s cubic-bezier(.46,.46,.3,.99);
      transform: translateX(calc(-100%));
      transition-delay: 0.1s;

      p{
        margin: 0 0 1rem 0;
      }
    }

    .c-card_deco{
      position: absolute;
      top: 0;
      right: 1px;
      width: 100%;
      transform-origin: top right;
      transform: scale(0.4);
      z-index: 0;
    }

    &:hover{
      .c-card_front_title, .c-card_front_text{
        transform: translateX(2rem);
      }
      .c-card_back{
        transform: translateX(0);
      }

      .c-card_fleche{
        span{
          height: rem(40px);
          min-width: rem(40px);
          transform: rotate(-180deg);
        }
      }
    }
  }

  .ensavoir {
    font-family: 'Roboto';
    .line {
      margin: 0 1rem;
      flex-grow: 1;
      height: 1px;
      background-color: $black;
    }

    .dot {
      height: rem(18px);
      width: rem(18px);
      border-radius: 50%;
      background-color: $second;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        background-color: white;
      }
    }
  }

  &.-projets{
    .c-card_exp{
      display: flex;
      align-items: center;
      span{
        &:not(:last-of-type){
          display: flex;
          align-items: flex-end;
          &::after{
            content: '';
            width: 3px;
            height: 3px;
            background-color: $grey;
            border-radius: 50%;
            margin-left: 0.5rem;
            margin-bottom: 0.4rem;
          }
        }
      }

      &::before{
        content: '';
        width: 3px;
        height: 12px;
        background-color: $primary;
        margin-right: 0.5rem;
        margin-bottom: 0.3rem;
      }
    }

    .c-card_img{
      position: relative;  

      figure{
        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: 0.3s ease-out;
          background-color: rgba($color: $primary, $alpha: 0.3);
          border-radius: 2rem;
        }
      }
      .c-card_btn_voir{
        position: absolute;
        right: 4rem;
        bottom: rem(-47px);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: rem(114px);
        width: rem(114px);

        .c-card_voir{
          height: rem(94px);
          width: rem(94px);
          border-radius: 50%;
          background-color: $primary;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s ease-out;
        }  
      }
    }

    &:hover{
      .c-card_img{
        figure{
          &::before{
            opacity: 1;
          }
        }
        .c-card_btn_voir{
          .c-card_voir{
            height: 100%;
            width: 100%;
          }
        }  
      }
    }
  }
}
