.b-custom-intro{
    position: relative;
    min-height: rem(480px);
    .b-custom-intro_breadcrumb{
        display: flex;
        position: absolute;
        bottom: 2rem;

        a, span{
            text-transform: uppercase;
        }

        .pipe{
            margin-left: 1rem;
            display: flex;
            align-items: center;

            &::before{
                content: '';
                width: 1.5rem;
                height: 2px;
                background-color: $grey;
                margin-right: 1rem;
            }
        }
    }

    .b-custom-intro_img{
        position: absolute;
        width: 40vw;
        height: 100%;
        right: 0;
        top: 0;
    }
}

.b-custom-intro_nav{
    position: relative;

    nav{
        padding: 2rem;
        border: 2px solid $grey;
        border-radius: 2rem;

        .u-margin-bottom-small{
            border-bottom: 2px solid $grey;
        }
    }
}
