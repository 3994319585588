.c-video {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    iframe, object, embed, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.c-video_wrap {
    cursor: pointer;
}

:root {
    --plyr-color-main: #049DD7;
}

.plyr--video {
    border-radius: 0.5rem;
}