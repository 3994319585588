// ==========================================================================
// Utilities / Widths
// ==========================================================================

////
/// @link https://github.com/inuitcss/inuitcss/blob/6eb574f/utilities/_utilities.widths.scss
////

/// Which fractions would you like in your grid system(s)?
/// By default, the boilerplate provides fractions of one whole, halves, thirds,
/// quarters, and fifths, e.g.:
///
/// @example css
///   .u-1/2
///   .u-2/5
///   .u-3/4
///   .u-2/3
$widths-fractions: 1 2 3 4 5 6 !default;

@include widths($widths-fractions);

$breakpoints-from: (
  'from-tiny' : $from-tiny,
  'from-small': $from-small,
  'from-medium': $from-medium,
  'from-large': $from-large,
  'from-midsmall': $from-midsmall,
) !default;

@each $breakpoint, $breakpoint-value in $breakpoints-from {
    @media (min-width: #{$breakpoint-value}) {
      @include widths($widths-fractions, #{$breakpoint-delimiter}#{$breakpoint});
    }
}

$breakpoints-to: (
  'to-tiny': $to-tiny,
  'to-small': $to-small,
  'to-medium': $to-medium,
  'to-large': $to-large,
  'to-midsmall': $to-midsmall,
  'to-enormous': $to-enormous,
) !default;

@each $breakpoint, $breakpoint-value in $breakpoints-to {
  @media (max-width: #{$breakpoint-value}) {
    @include widths($widths-fractions, #{$breakpoint-delimiter}#{$breakpoint});
  }
}

.u-100{
  width: 100%;
}
