// ==========================================================================
// Base / Page
// ==========================================================================

//
// Simple page-level setup.
//
// 1. Set the default `font-size` and `line-height` for the entire project,
//    sourced from our default variables.
// 2. Ensure the page always fills at least the entire height of the viewport.
//
html {
    min-height: 100%; /* [2] */
    color: $primary;
    font-family: $font-family;
    font-weight: $light;
    line-height: $line-height; /* [1] */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: $to-small) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-small) and (max-width: $to-medium) {
        font-size: $font-size - 2px;
    }

    @media (min-width: $from-medium) and (max-width: $to-large) {
        font-size: $font-size - 1px;
    }

    @media (min-width: $from-large) and (max-width: $to-huge) {
        font-size: $font-size; /* [1] */
    }

    @media (min-width: $from-huge) and (max-width: $to-gigantic) {
        font-size: $font-size + 1px;
    }

    @media (min-width: $from-gigantic) and (max-width: $to-colossal) {
        font-size: $font-size + 2px;
    }

    @media (min-width: $from-colossal) {
        font-size: $font-size + 4px;
    }

    &.is-loading {
        cursor: wait;
    }

    &.has-scroll-smooth {
        overflow: hidden;
    }

    &.has-scroll-dragging {
        user-select: none;
    }
}

body {
    background-color: white !important;
    transition: background-color $speed $easing;
    .has-scroll-smooth & {
        overflow: hidden;
    }
}

main {
//   padding-top: rem($header-height / 1.5);
}

.app {
  opacity: 0;
  position: relative !important;
  transition: opacity 0.6s $easing;
}

.is-animated .app {
  opacity: 1!important;
}

::selection {
    background-color: $selection-background-color;
    color: $selection-text-color;
    text-shadow: none;
}

a {
    color: $primary;
    transition: 0.3s $easing;

    @include u-hocus {
        color: $link-hover-color;
    }
}

.e-page_intro{
    // height: rem(363px);
    padding: 4rem;
    border-radius: rem(8px);
    overflow: hidden;
    &.-img {
        min-height: rem(363px);
    }

    .e-page_intro_left{
        height: 100%;

        a{
            color: $black;
            text-decoration: underline;
        }

        .-wrap{
            gap: 1rem;
        }

        .pipe{
            width: 2.5rem;
            height: 1px;
            background-color: $black;
        }
    }

    .e-page_intro_right{
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;

        .e-page_intro_img{
            height: 100%;
            svg{
                height: 100%;
                position: absolute;
                left: -1px;
                top: 0;
            }
        }
    }

    @media (max-width: $from-medium){
        // &.-img {
        //     min-height: auto;
        // }
        .e-page_intro_right{
            // display: none;
            height: 50%;
            width: 100%!important;
            top: auto;
            bottom: 0;
            svg {
                display: none;
            }
        }
    }

    @media (max-width: $to-small) {
        padding: rem($unit-small);
    }
}

.e-page_intro_home{
    width: 100vw;
    height: 100vh;

    .e-page_intro_home_container{
        z-index: 2;
        position: relative;
        height: 100%;

        .e-page_intro_home_logo{
            // width: fit-content;
            
            figure{
                padding: 1rem;
                width: 16rem;
                background-color: white;
            }
        }

        a{
            &:not(.c-button){
                border-radius: 1rem;
                padding: 4rem 2rem 2rem;
            }
        }
    }
}

section blockquote{
    color: $primary;
    font-size: $font-size-h3;
    text-align: center;
    -webkit-text-stroke: 1px currentColor;
    line-height: 2rem;
    margin: unset;
}

::-webkit-scrollbar {
    width: .4em;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
}

::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid $grey;
    border-radius: 5px;
}

.acf-map{
    min-height: rem(500px);
}