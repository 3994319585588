.b-transition{
    text-align: center;
    position: relative;
    overflow: hidden;

    p{
        margin: 0;
        font-family: 'Hind';
        font-size: $font-size-h6;
        color: $grey;
        padding: 0 5rem;
    }

    &::after{
        content: '';
        width: 300vw;
        height: 300vw;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: -100vw;
        background-color: $second;
        z-index: -1;
    }

    @media (max-width: 500px){
        &::after{
            width: 500vw;
            height: 500vw;
            left: -200vw;
        }
    }
}
