.b-actu-evenement{

    @media(max-width: $from-medium){
        .b-actu-evenement_container{
            flex-direction: column;
            gap: 8rem;

            .actu, .evenement{
                margin: 0 auto;
                width: 75% !important;
            }
        }
    }

    @media(max-width: $from-small) {
        .b-actu-evenement_container {
            .actu, .evenement {
                width: 100% !important;
            }
        }
    }
}
