.b-equipe {
    .b-equipe_card_container{
        transform: translateY(5rem);
    }

    .b-equipe_card{
        height: rem(360px);
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: flex-end;

        &.-decale{
            transform: translateY(-5rem);
        }

        .b-equipe_top{
            z-index: 1;
            position: relative;
        }

        figure{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            display: flex;
            padding: 0 1rem;

            &::after{
                content: "";
                width: calc(100% - 2rem);
                height: 100%;
                position: absolute;
                top: 0;
                left: 1rem;
                background: linear-gradient(to bottom, transparent, rgba($primary, 0.62));
                padding: 0 1rem;
            }
        }
    }

    @media(max-width: $from-tiny){
        .b-equipe_title{
            flex-direction: column;
        }

        .b-equipe_card{
            &.-decale{
                transform: translateY(0);
            }
        }
    }
}
