.c-text{
    font-family: 'Roboto';
    font-weight: $normal;
    font-size: 16px;
    line-height: 1.2rem;
    color: $blue;

    &.-font-larger{
        font-size: $font-size-h6;
    }

    
    ul li{
        display: flex;
        align-items: center;
        &::before{
            content: '';
            min-width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: $primary;
            margin-right: 5px;
        }
    }
    
    ol li{
        list-style: none;
        counter-increment: li;
        color: $black;
        padding-left: rem($unit-tiny);

        &::before {
            content: counter(li) '.';
            color: $primary;
            display: inline-block;
            width: 1em;
            margin-left: -1em
        }
    }

    img{
        border-radius: rem(8px);
        max-width: 100%;
    }

    .cff-wrapper {
        #cff {
            img {
                width: unset;
            }
    
            .cff-author {
                .cff-author-img {
                    display: none;
                }
        
                .cff-author-text {
                    .cff-date, .cff-page-name {
                        margin-left: 0 !important;
                    }

                    .cff-page-name {
                        display: none;
                    }

                    .cff-date {
                        color: $black !important;
                        font-weight: 500;
                        font-family: "Roboto";
                        font-size: 1rem;
                    }
                }

            }
            
            .cff-post-text, .cff-post-text .cff-text, .cff-post-text .cff-text a, .cff-media-link a, .cff-expand, .cff-expand a, .cff-post-links a {
                color: $black !important;
                font-size: rem($font-size-h6);
                margin-top: $unit-small;
                font-family: "Roboto";
                font-weight: 400;
            }

            .cff-post-links {
                margin: $unit-small 0 1.2rem 0;
                display: flex;
                align-items: center;
                width: 100%;
                
                a {
                    text-transform: uppercase;
                    font-weight: bold;
                    margin-top: 0;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    
                    .line {
                        margin: 0 1rem;
                        flex-grow: 1;
                        height: 1px;
                        background-color: $black;
                    }
    
                    .dot {
                        height: 20px;
                        width: 20px;
                        border-radius: 50%;
                        position: relative;
                        border: 2px solid $second;
                        transition: 0.3s cubic-bezier(.46, .46, .3, .99);
                    }
                    
                    &:hover {
                        .dot {
                            border-width: 10px;
                        }
                    }
                }
            }


            .cff-share-container {
                display: none;
            }
        }
    }


    max-width: 100%;
    word-break: break-word;
    // *:not(strong, b, br, em, i, q, .cff-space, .o-ratio) {
    //     margin: rem($unit-tiny) 0;
    //     &:first-child {
    //         margin-top: 0;
    //     }
    //     &:last-child {
    //         margin-bottom: 0;
    //     }
    // }
    
    blockquote {
        border-radius: 0.5rem;
        background-color: $light-blue;
        color: $black;
        text-align: left;
        padding: rem($unit-small);
        margin: 0;
        position: relative;
    }

    a:not(.c-button) {
        color: var(--color-primary);
        text-decoration: underline;
        .c-button_icon {
            display: none;
        }
    }

    img {
        border-radius: calc(var(--radius) * 2);
        overflow: hidden;
        max-width: 100%;
    }

    .alignleft,
    img.alignleft {
        display: inline;
        float: left;
        margin-right: 1.5em;
    }
    .alignright,
    img.alignright {
        display: inline;
        float: right;
        margin-left: 1.5em;
    }
    .aligncenter,
    img.aligncenter {
        clear: both;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

}
