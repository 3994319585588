.c-tarifs{
    .c-tarifs_container{
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // justify-content: center;
        // gap: $unit-small;

        .c-tarifs_component{
            border-radius: 1rem;
            background-color: $grey-tarif;
            align-items: inherit;
        }
        
        .c-tarifs_fichier{
            display: flex;
            flex-direction: column;

            &::before{
                content: '';
                height: 2px;
                width: 100%;
                background-color: $black;
                margin-bottom: 1rem;
            }
        }
    }
}
