.b-mot-anime{
    .b-mot-anime_content{
        @media (max-width: $from-tiny){
            flex-direction: column;

            div{
                width: 100% !important;
            }
        }
    }
}
