.b-texte-anime{
    .mobile{
        display: none;
    }

    @media (max-width: $from-midsmall){
        .desktop{
            display: none;
        }

        .mobile{
            display: flex;
        }

        &.u-margin-bottom{
            margin-bottom: 10rem !important;
        }

        &.u-margin-top{
            margin-top: 10rem !important;
        }
    }

    @media (max-width: $from-tiny){
        &.u-margin-bottom{
            margin-bottom: 3rem !important;
        }

        &.u-margin-top{
            margin-top: 3rem !important;
        }
    }
}
