.b-formulaire{
    .b-formulaire_desc{
        padding-bottom: 1rem;
        border-bottom: 1px solid $primary;
        width: 100%;
    }

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        label{
            width: 100%;
        }

        input{
            height: rem(40px);
            background-color: $light-blue;
            border-radius: 10px;
            margin-top: 2px;
            padding: 0 1rem;
        }

        .b-formulaire_submit{
            span{
                display: flex;
                align-items: center;
                position: relative;

                &::before{
                    content: '';
                    height: rem(48px);
                    width: rem(48px);
                    border-radius: 50%;
                    background-color: $light-blue;
                    position: absolute;
                    left: -24px;
                    z-index: -1;
                    transition: 0.3s ease-out;
                }
            }

            &:hover{
                span{
                    &::before{
                        transform: translateX(100%);
                    }
                }
            }
        }
    }
}
