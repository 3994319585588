.b-relation {
    &.b-relation_expertises, &.b-relation_projets{
        .o-container{
            position: relative;
        }
    }
    .c-slider_container {
        .swiper-slide {
            .c-card {
                opacity: 0.5;
                transition: $speed $easing;
            }
            &.swiper-slide-active, &.swiper-slide-next {
                .c-card {
                    opacity: 1;
                }
            }
        }
    }

    .b-relation_title{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        span{
            &:first-of-type{
                &::after{
                    
                }
            }
        }
    }
}

.b-relation_listing{
    &.-expertises{
        .b-relation_listing_container{
            position: relative;
            width: calc(100% * 1 / 3);

            &:nth-of-type(2){
                transform: translateY(0);
            }

            &.-grey{
                opacity: 0.32;
            }

            &::before{
            content: "";
            position: absolute;
            width: 100% ;
            height: 100%;
            border: 3px solid $primary;
            left: 0;
            top: 0;
            transition: 0.8s cubic-bezier(.46,.46,.3,.99);
            border-radius: 0.5rem 0.5rem 3rem 0.5rem;
            }

            &:hover{
                &::before{
                    transform: rotate(-4deg);
                }
            }
        }
        
        .b-relation_bulle1{
            position: absolute;
            top: -8rem;
            transform: scale(1.5);
            right: 6rem;
        }

        .b-relation_bulle2{
            position: absolute;
            bottom: 0;
            right: 38%;
        }
        
        .b-relation_chevron{
            position: absolute;
            top: -9rem;
            left: 2rem;
            transform: scale(0.6) rotate(200deg);
        }

        @media(max-width: 900px){
            flex-direction: column-reverse !important;

            .b-relation_listing_container{
                display: flex;
                align-self: center;
                min-width: 250px;
                margin-left: inherit !important;
                margin-right: inherit !important;

                &:first-of-type{
                    display: flex;
                    align-self: flex-end;
                }
                
                &:last-of-type{
                    display: flex;
                    align-self: flex-start;
                }

                &:nth-of-type(2){
                    transform: translateY(0) !important;
                }
            }

            .b-relation_bulle1{
                top: 12rem;
                right: 9rem;
            }

            .b-relation_bulle2{
                bottom: 10%;
                left: 20%;
            }
        }

        @media(max-width: 650px){
            .b-relation_chevron{
                display: none;
            }
        }

        @media (max-width: $from-tiny){
            .b-relation_listing_container{
                &:first-of-type{
                    align-self: inherit;
                }
                
                &:last-of-type{
                    align-self: inherit;
                }
            }

            .b-relation_bulle1, .b-relation_bulle2{
                display: none;
            }
        }
    }

    &.-projets{
        flex-wrap: wrap;
        .b-relation_listing_container{
            width: 50%;

            figure{
                img{
                    height: 375px;
                    border-radius: 2rem;
                }
            }

            &.-decale{
                transform: translateY(5rem);
            }
        }

        .b-relation_bulle1{
            position: absolute;
            top: -5rem;
            transform: scale(1.5);
            right: 25%;
        }

        .b-relation_bulle2{
            position: absolute;
            top: -2rem;
            right: 20%;
        }
        
        .b-relation_chevron{
            position: absolute;
            bottom: -5rem;
            left: 2rem;
            transform: scale(0.6) rotate(95deg);
        }

        @media (max-width: 910px){
            flex-direction: column-reverse !important;

            .b-relation_listing_container{
                display: flex;
                align-items: center;
                align-self: flex-start;
                min-width: 378px;
                padding-right: inherit !important;

                &.-decale{
                    align-self: flex-end;
                    padding-left: inherit !important;
                    transform: translateY(0);
                }
            }

            .b-relation_bulle1{
                top: -9rem;
                right: 7rem;
            }

            .b-relation_bulle2{
                top: -5rem;
                right: 3rem;
            }
        }

        @media(max-width: $from-tiny){
            .b-relation_listing_container{
                display: flex;
                align-self: center;

                &.-decale{
                    align-self: inherit;
                }
            }
        }

        @media (max-width: 420px){
            .b-relation_listing_container{
                transform-origin: top left;
                transform: scale(0.75) !important;
            }
        }
    }
}
