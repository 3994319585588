.c-accordion_item {
  border-bottom-left-radius: 1rem;
  .c-accordion_header {
    padding: 2rem;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid $black;
    border-radius: rem(8px);
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) background-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    .c-heading {
      padding-right: 50px;

      @media (max-width: $from-tiny){
        padding-right: 25px;
      }
    }
  }
  .c-accordion_plus {
    position: relative;
    width: rem(32px);
    height: rem(32px);
    border-radius: 50%;
    background-color: $second;
    flex-shrink: 0;

    span{
      width: rem(10px);
      height: 2px;
      background-color: $black;
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 2;
      transform: translate(-50%, -50%);

      &.ver{
        transform-origin: center;
        transform: translate(-50%, -50%) rotate(90deg);
        transition: $speed $easing;
      }

      @media (max-width: $from-large){
        left: 50%;
        top: 50%;
      }
    }
    
    &::after{
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      transition: $speed $easing;
      background-color: white;
      border-radius: 50%;
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(-100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover{
    .c-accordion_plus::after {
      transform: scale(0);
    }
  }

  &.is-open {
    .c-accordion_header{
      background-color: $black;
      border: unset;

      div, div span{
        color: white;
      }
    }
    .c-accordion_main {
      height: auto;
      padding: 0 2rem 2rem;

      @media (max-width: $from-small){
        padding: unset;
      }
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
    }
    .c-accordion_plus{
      span{
        &.ver{
          transform: translate(-50%, -50%) rotate(0);
        }
      }
    }
    .c-accordion_plus::after {
      background-color: $second;
    }

    header{
      .u-c-grey{
        color: $primary;
      }
    }
  }

  header{
    .u-c-grey{
      transition: 0.3s ease-out;
    }
  }

  &:hover{
    header{
      .u-c-grey{
        color: $primary;
      }
    }
  }
}

.c-accordion_scroll{
  opacity: 0;
  transform: translateY(25rem);

  .-nb1{
    transition: 0.7s ease-out 0.2s;
  }
  

  &.-title{
    transition: 0.6s ease-out;
  }

  &.-content{
    transition: 0.9s ease-out;
  }

  &.c-accordion_scroll_title{
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  &.c-accordion_scroll_content{
    opacity: 1 !important;
    transform: translateY(0) !important;

    .-nb1{
      transform: translateY(-5rem) !important;
    }
  }
}


