.b-presentation{
    .b-paragraphe_title {
        font-style: italic;
    }

    .b-paragraphe_text_container {
        div {
            margin: 0 0 auto auto;

            .b-paragraphe_btn {
                margin: auto auto 0 0;
            }
        }
    }

    .b-paragraphe_btn {
        &.-left {
            margin: 2rem 0 0 0;
        }
    }

    .c-heading {
        &.-h2 {
            font-style: italic;
            font-weight: 400;
            -webkit-text-stroke: 1px currentColor;
        }

        &.-h3 {
            font-weight: 400;
            -webkit-text-stroke: 1px currentColor;
        }
    }

    @media (max-width: $from-small) {
        .b-paragraphe_text_container {
            margin-left: unset !important;
            padding-top: 1rem;

            .c-text {
                width: 100% !important;
            }
        }
    }

    @media (max-width: $from-tiny) {
        .b-paragraphe_text_container {
            flex-direction: column;
        }
    }
}