.c-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $primary;
  z-index: 2000000001;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem($unit);
  svg {
    width: rem(300px);
  }
  transition: transform 1s $easing , visibility 0s 2s, border-radius 0.5s ease-out;
  .logo {
    transform: translateY(rem($unit));
    opacity: 0;
    transition: 1.5s $easing;
    color: $white;

    svg{
      fill: white;
    }
  }
}

.is-animated.is-ready , .is-transitioned, .is-transitioning {
  .c-loader {
    transform: translateY(-100%);
    border-radius: 50%;
    visibility: hidden;
    height: 100vh;

    .logo {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }

  
}

.is-animated.is-ready {
  .b-accueil{
    .b-accueil_top{
      .b-accueil_top_title{
        .b-accueil_title{
          transform: translateY(0) !important;
          transition: 1s cubic-bezier(.46,.46,.3,.99) 0s !important;

          &.-titre{
            transition: 0.5s cubic-bezier(.46,.46,.3,.99) 0.3s !important;
          }
        }
      }
    }

    .b-accueil_bottom{
      .b-accueil_deco{
        transform: translateY(0);
        transition: 1s cubic-bezier(.46,.46,.3,.99);
        &::before{
          border-radius: 50%;
          transition: 1s cubic-bezier(.46,.46,.3,.99);
        }
      }
    }
  }
}

.is-loading{
  #projet{
    .b-paragraphe{
      .b-paragraphe_title{
        transform: translateY(5rem);
        opacity: 0;
      }

      .b-paragraphe_text_container{
        transform: translateY(5rem);
        opacity: 0;
      }
    }
  }
  
}

.is-animated{
  
}

.is-loaded{
  // .b-accueil{
  //   .b-accueil_top{
  //     .b-accueil_top_title{
  //       .b-accueil_title{
  //         transform: translateY(0) !important;
  //         transition: 1.5s cubic-bezier(.46,.46,.3,.99) 3s !important;

  //         &.-titre{
  //           transition: 1.5s cubic-bezier(.46,.46,.3,.99) 3.5s !important;
  //         }
  //       }
  //     }
  //   }

  //   .b-accueil_bottom{
  //     .b-accueil_deco{
  //       transform: translateY(0);
  //       transition: 2s cubic-bezier(.46,.46,.3,.99) 2s;
  //       &::before{
  //         border-radius: 50%;
  //         transition: 2s cubic-bezier(.46,.46,.3,.99) 2.2s;
  //       }
  //     }
  //   }
  // }
  .e-page_intro{
    transform: translateY(0) !important;
    transition: transform 0.9s ease-out 0.6s;

    .e-page_intro_subtitle{
      transform: translateY(0);
      transition: 0.9s ease-out 0.7s;
    }

    .e-page_intro_end{
      display: flex;
      width: 100%;
      transition: width 0s ease-out 1.5s, 0.9s ease-out 1.5s;
      margin-left: 0;

    }

    .e-page_intro_start{
      opacity: 0;
      transition: 0.6s ease-out 1.5s;
    }
  }

  #projet{
    transform: translateY(0);
    opacity: 1;
    transition: 0.6s ease-out 2.4s;

    .b-paragraphe{
      .b-paragraphe_title{
        transform: translateY(0);
        transition: 0.6s ease-out 2.7s;
        opacity: 1;
      }

      .b-paragraphe_text_container{
        transform: translateY(0);
        transition: 0.6s ease-out 3s;
        opacity: 1;
      }
    }

    .e-page_intro_aside_component{
      transform: translateY(0);
      transition: 0.6s ease-out 3s;
      opacity: 1;
    }
  }
}
