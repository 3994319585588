.c-heading {
    line-height: $line-height-h;
    font-family: 'Roboto';

    &.-title{
      font-weight: $semibold;
      width: fit-content;
    }

    &.-noMargin {
      margin-bottom: 0;
    }

    &.-contenu{
      @media (max-width: 650px){
        order: 2;
        margin-top: 2rem;
      }
    }

    &.-h1 {
        font-size: rem($font-size-h1);
        line-height: $line-height-default;
        font-family: 'Mandala';
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1)} - 10px);
        }
        @media (max-width: $to-tiny){
          font-size: 2.5rem;
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        // @media (max-width: $to-medium) {
        //   font-size: calc(#{rem($font-size-h2)} - 10px);
        // }
        // @media (max-width: $to-small) {
        //   font-size: calc(#{rem($font-size-h2)} - 15px);
        // }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        // @media (max-width: $to-medium) {
        //   font-size: calc(#{rem($font-size-h3)} - 10px);
        // }
        // @media (max-width: $to-small) {
        //   font-size: calc(#{rem($font-size-h3)} - 15px);
        // }
    }

    &.-tiny{
      font-size: $font-size-tiny;
    }

    &.-small {
      font-size: rem($font-size-small);
    }

    &.-medium {
      font-size: rem($font-size-medium);

      // @media (max-width: $to-small) {
      //   font-size: calc(#{rem($font-size-medium)} - 5px);
      // }
    }

    &.-large {
      font-size: rem($font-size-large);
    }

    &.-h4 {
      font-family: 'Mandala';
      font-size: rem($font-size-h4);
    }

    &.-h5 {
        font-size: rem($font-size-h5);
    }

    &.-h6 {
      font-size: rem($font-size-h6);
    }

    &.-normal {
      font-size: rem($font-size);
    }
    &.-opacity {
      opacity: 0.5;
    }
    &.-mandala{
      font-family: 'Mandala';
    }
    &.-upper{
      text-transform: uppercase;
    }

    &.-webkit{
      -webkit-text-stroke: 1px currentColor;
    }

    &.-italic{
      font-style: italic;
      -webkit-text-stroke: 1px currentColor;
    }

    @media (max-width: 650px){
      &.-contenu{
        order: 2;
        margin-top: 2rem;
      }
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}