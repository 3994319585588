.b-cta{
    .b-cta_wrap{
        position: relative;

        &::after{
            content: '';
            width: 50vw;
            height: 100%;
            position: absolute;
            left: 95%;
            top: 0;
            // background-color: $primary;
            background: linear-gradient(0deg, #03678F 0%, $primary 100%), $primary;
            z-index: -1;
        }
    }

    .b-cta_container{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        .b-cta_deco{
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: auto;
            transform: rotateY(180deg);
            display: flex;
            align-items: center;

            svg{
                height: 100%;
            }

            &::before{
                content: '';
                width: 10vw;
                height: 100%;
                // background-color: $primary;
                background: linear-gradient(0deg, #03678F 0%, $primary 100%), $primary;
            }
        }

        img{
            border-radius: 0.5rem;
        }

    }
    .b-cta_content_container{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        min-height: rem(488px);

        .b-cta_content{
            border-radius: 0.5rem;
        }
    }

    &.-droite{
        .b-cta_content_container {
            justify-content: flex-start;
        }

        .b-cta_container{
            .b-cta_deco{
                left: 0;
                transform: inherit;
            }
        }

        .b-cta_wrap{
            &::before {
                content: "";
                width: 50vw;
                height: 100%;
                position: absolute;
                right: 95%;
                top: 0;
                // background-color: #049DD7;
                background: linear-gradient(0deg, #03678F 0%, $primary 100%), $primary;
                z-index: -1;
            }
            
            &::after{
                content: unset;
            }
        }
    }
}
