.b-lien{
    position: relative;
    z-index: 4;

    .b-lien_bg_deco{
        position: absolute;
        left: -15rem;
        z-index: -1;
        top: -15rem;
    }

    @media (max-width: $from-midsmall){
        .b-lien_container{
            .b-lien_content{
                flex-direction: column;
                align-items: center !important;

                a{
                    width: 50% !important;
                    min-width: rem(300px);
                }
            }
        }
    }
}

.b-lien_container{
    .b-lien_content{
        gap: 0.5rem;

        figure{
            width: unset;

            img{
                width: rem(26px);
            }
        }

        .b-lien_link{
            border-radius: 0.5rem;
            padding: 1.5rem;
            transition: $speed $easing;

            &:hover{
                background-color: $black;

                span{
                    color: white;  
                }
                
                img{
                    filter: invert(1) grayscale(100%);
                } 
            }
        }
    }
}