.u-c-primary {
  color: $primary;
}
.u-c-second {
  color: $second;
}
.u-c-white {
    color: $white;
}
.u-c-white2 {
    color: $white-footer2;
}
.u-c-grey{
  color: rgba($color: white, $alpha: 0.5);
}
.u-c-red{
  color: $red;
}
.u-c-blue{
  color: $blue;
}
.u-c-lightBlue{
  color: $light-blue;
}
.u-c-black{
  color: $black;
}
.u-c-gradient{
  background: linear-gradient(90deg, rgba(201,86,170,1) 0%, rgba(201,86,170,1) 10%, rgba(83,143,209,1) 90%, rgba(83,143,209,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.u-bg-primary {
  background-color: $primary;
}
.u-bg-second {
  background-color: $second;
}
.u-bg-red{
  background-color: $red;
}
.u-bg-black {
    background-color: $black;
    .u-c-white {
      -webkit-text-stroke: 0px;
    }
}
.u-bg-blue{
  background-color: $blue;
}
.u-bg-white {
    background-color: $white;
}
.u-bg-lightBlue {
  background-color: $light-blue;
}

.u-bg-intro {
  background: linear-gradient(0deg, #03678F 0%, $primary 100%), $primary;
}
