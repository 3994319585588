.c-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: $speed $easing;
    opacity: 0;
    visibility: hidden;
    z-index: 11;
    padding: rem($unit-small);

    .c-modal_image {
      position: relative;
      height: rem(200px);
    }
    .c-modal_wrap {
      max-width: rem(700px);
      width: 100%;
      background-color: $white;
      border-radius: 8px;
      position: relative;
      pointer-events: auto;
      max-height: 80vh;
      overflow: auto;
    }
    .c-modal_head {
      background-color: $primary;
      color: $white;
      padding: rem($unit-tiny) rem($unit-small);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .c-modal_content {
      position: relative;
      z-index: 2;
      padding: rem($unit-small);
      max-height: 80vh;
      overflow: auto;
    }
    .c-modal_bg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba($black, 0.6);
    }
    .c-modal_close {
      // position: absolute;
      // top: -15rem;
      // left: 50%;
      cursor: pointer;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: rem($unit-small);
    }
    // .c-modal_wrap {
    //     position: relative;
    //     width: 65vw;

    //     form{
    //       &>div{
    //         margin: 0 auto;

    //         input{
    //           font-size: 2rem;
    //           color: $black;
    //           height: 3rem;
    //           line-height: 3rem;
    //         }

    //         input::placeholder{
    //           font-size: 2rem;
    //           color: $black;
    //           font-style: italic;
    //         }
    //       }
    //     }
    // }
    &.is-open {
      opacity: 1;
      visibility: visible;
    }

    .c-modal_search_wrap {
      border: 2px solid $black;
      border-radius: 8px;
      .c-form_label {
        top: 50%;
        transform: translateY(-50%);
        left: 1rem;
        transition: $speed $easing;
        font-size: rem($font-size-h3);
        color: $black;
      }
      
      .c-form_item {
        width: 100%;
        &.is-active {
          .c-form_label {
            top: 0;
            font-size: rem($font-size);
          }
        }
      }
      .c-button {
        flex-shrink: 0;
      }
    }

    .c-modal_search {
        color: $white;
        position: relative;
        .c-form_label {
            font-size: $font-size-h1;
            opacity: 1;
            left: 0;
        }
        .c-form_input {
            border-bottom: 4px solid $grey;
            font-size: $font-size-h3;
            line-height: 1;
            padding: 0.7rem 0;
            padding-right: rem(50px);
            height: rem(100px);
            color: $grey;
        }
        .c-modal_search_button {
            position: absolute;
            right: 0;
            bottom: calc(4px + 1rem);
            svg {
                width: rem(40px);
            }
        }
        .c-form_item.is-active label {
            transform: translateY(-100%);
        }
    }
}
