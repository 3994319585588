.c-slider{
    transition-duration: 0.6s !important;
}

.c-slider_container {
    overflow: hidden;
    min-width: 100%;
    height: 100%;

    &.-offset-small {
        margin-right: rem(-$unit-small);
    }

    &.-offset {
        margin-right: rem(-$unit);
    }

    &.-offset-tiny {
        margin-right: rem(-$unit-tiny);
    }

    &.-offset-double {
        margin-right: calc(#{rem(-$unit)} * 2);
    }

    .swiper-slide {
        height: auto !important;
    }
}

.c-slider_pagination {
    .swiper-pagination {
        position: relative!important;
    }
    .swiper-pagination-bullet {
        width: rem(10px);
        height: rem(10px);
        border: 1px solid $primary!important;
        background-color: transparent!important;
        opacity: 1!important;
    }
    .swiper-pagination-bullet-active {
        background-color: $primary!important;
    }
}