// ==========================================================================
// Settings / Config
// ==========================================================================

// Context
// =============================================================================
// The current stylesheet context. Available values: frontend, editor.
$context: frontend  !default;

// Path is relative to the stylesheets directory.
$assets-path: "../"  !default;

// Typefaces
// =============================================================================
$font-sans-serif: sans-serif;
$font-Mandala: 'Mandala';
$font-Radikal: $font-Mandala;
$font-Roboto: 'Roboto';

// Typography
// =============================================================================
// Base
$font-size:   14px;
$line-height: 18px / $font-size;
$line-height-default: 1;
$font-family: $font-Roboto;
$color: $black;
// Headings
$font-size-h1:  56px  !default;
$font-size-h2:  40px  !default;
$font-size-h3:  24px  !default;
$font-size-h4:  24px  !default;
$font-size-h5:  18px  !default;
$font-size-h6:  16px  !default;
$font-size-small: 14px !default;
$font-size-tiny: 11px !default;
$font-size-medium: 24px !default;
$font-size-large: 80px !default;

//$line-height-h: $line-height;
$line-height-h: $line-height-default;
// Weights
$light:     300;
$normal:    400;
$medium:    500;
$semibold:  600;
$bold:      700;
$extrabold: 900;

// Transitions
// =============================================================================
$speed:  0.3s;
$easing: cubic-bezier(.46, .46, .3, .99);

// Spacing Units
// =============================================================================
$unit:       60px;
$unit-small: 27px;
$unit-tiny:  18px;
$unit-tinier: 9px;
$unit-large: 120px;
$unit-huge: 200px;

// Container
// ==========================================================================
$container-width:           1280px;
$container-large-width:     1440px;
$container-medium-width:    1100px;
$container-small-width:     768px;
$padding:                   $unit;

// Breakpoints
// =============================================================================
$from-tiny:     500px              !default;
$to-tiny:       $from-tiny - 1     !default;
$from-small:    700px              !default;
$to-small:      $from-small - 1    !default;
$from-midsmall:   800px            !default;
$to-midsmall:   $from-midsmall - 1 !default;
$from-medium:   1000px             !default;
$to-medium:     $from-medium - 1   !default;
$from-large:    1200px             !default;
$to-large:      $from-large - 1    !default;
$from-big:      1400px             !default;
$to-big:        $from-big - 1      !default;
$from-huge:     1600px             !default;
$to-huge:       $from-huge - 1     !default;
$from-enormous: 1800px             !default;
$to-enormous:   $from-enormous - 1 !default;
$from-gigantic: 2000px             !default;
$to-gigantic:   $from-gigantic - 1 !default;
$from-colossal: 2400px             !default;
$to-colossal:   $from-colossal - 1 !default;


$header-height: 144px !default;
