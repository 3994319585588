.b-clients{
    .b-clients_logos{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        figure{
            width: 25%;
            min-width: 149px;
            padding: 0 2rem 2rem;

            img{
                opacity: 0.3;
                transition: 0.3s ease-out;

                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    .b-clients_card{
        overflow: hidden;
        position: relative;
        padding: 1rem 4rem; 

        &::before{
            content: '';
            height: rem(36px);
            width: rem(36px);
            background-color: $light-blue;
            position: absolute;
            top: -18px;
            left: -18px;
            transform: rotate(45deg);
        }

        &::after{
            content: '';
            height: rem(36px);
            width: rem(36px);
            background-color: $light-blue;
            position: absolute;
            bottom: -18px;
            right: -18px;
            transform: rotate(45deg);
        }
    }

    @media (max-width: 900px){
        .b-clients_content{
            flex-direction: column;

            .b-clients_chiffres{
                flex-direction: row;
                margin: 1rem 0 6rem 0;
                margin-right: inherit !important;
                justify-content: center;
                flex-wrap: wrap;

                .b-clients_card{
                    margin-top: inherit !important;
                    width: 221px;
                    
                    &:not(:last-of-type){
                        margin-right: 2rem;
                    }
                }
            }
        }
    }
}
